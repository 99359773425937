import Vue from 'vue'
import router from './router'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMask from 'v-mask'
import { BootstrapVue } from 'bootstrap-vue'

Vue.config.productionTip = false
Vue.use(VueAxios, axios) 
Vue.use(VueMask)
Vue.use(BootstrapVue)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')